<template>
  <div class="sessions-table" id="sessions-table">
    <vs-button
      v-tooltip="lang.metricSessions.download[languageSelected]"
      class="float-right"
      size
      :disabled="!this.report.length"
      radius
      type="flat"
      icon="icon-download"
      icon-pack="feather"
      @click="downloadAll"
    ></vs-button>
    <div class="vx-row">
      <vs-col vs-w="12">
        <div class="pt-4 pb-base vs-con-loading__container">
          <vs-table
            :sst="true"
            :total="totalItems"
            pagination
            :key="key"
            max-items="15"
            search
            @change-page="handleChangePage"
            sorted
            :data="reportTable"
            :no-data-text="lang.general.noDataText[languageSelected]"
          >
            <template slot="thead">
              <vs-th sort-key="date">  {{ lang.audit.date[languageSelected] }} </vs-th>
              <vs-th sort-key="userEmail"> {{ lang.audit.user[languageSelected] }} </vs-th>
               <!-- Futura implementación, nombre del bot -->
              <!-- <vs-th sort-key="botName"> {{ lang.audit.bot[languageSelected] }} </vs-th> -->
               <!-- Se oculta | Se utilizaba antes como evento -->
              <!-- <vs-th sort-key="path"> {{ lang.audit.path[languageSelected] }} </vs-th> -->
              <vs-th sort-key="event"> {{ lang.audit.event[languageSelected] }} </vs-th>
              <vs-th width="5%"> {{ lang.audit.details[languageSelected] }} </vs-th>
            </template>

            <template slot-scope="{ data }">
              <vs-tr
                class="bg-white"
                :key="indextr"
                v-for="(tr, indextr) in data"
              >
                <vs-td :data="data[indextr].dateTime">
                  {{ new Date(data[indextr].dateTime) | date(true) }}
                </vs-td>

                <vs-td :data="data[indextr].userEmail">
                  {{ data[indextr].userEmail }}
                </vs-td>

                <!-- <vs-td :data="data[indextr].botName">
                  Bot
                </vs-td> -->

                <!-- <vs-td :data="data[indextr].path">
                  {{ data[indextr].path }}
                </vs-td> -->

                <vs-td :data="data[indextr].translated_event">
                  {{ data[indextr].translated_event }}
                </vs-td>
                <vs-td :data="data[indextr].translated_event" width="5%">
                  <vs-button
                    size="small"
                    class="bot-item-btn"
                    icon="icon-info"
                    icon-pack="feather"
                    v-tooltip.bottom="
                      lang.audit.details[languageSelected]
                    "        
                    @click="openDetails(indextr)"
                  ></vs-button>
                </vs-td>
              </vs-tr>
            </template>
          </vs-table>
        </div>
      </vs-col>
    </div>
    <KonaPopup
        ref="auditDetail"
        class="auditDetail"
        :title="
          popupTitle
        "
      >
      <pre>{{auditDetail}}</pre>
    </KonaPopup>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import moment from 'moment';
import AuditService from '@/services/audit.service'

export default {
  name: 'AuditTableV1',
  components: {
    VsTable: () => import('@/components/vx-table/vsTable'),
    VsTh: () => import('@/components/vx-table/vsTh'),
    VsTr: () => import('@/components/vx-table/vsTr'),
    KonaPopup: () => import('@/components/KonaPopup.vue'),
    VsTd: () => import('@/components/vx-table/vsTd')
  },
  data: () => ({
    report: [],
    reportTable: [],
    totalItems: 0,
    key: new Date().getTime(),
    auditDetail: null,
    popupTitle: '',
  }),
  computed: {
    ...mapState(['lang', 'languageSelected']),
  },
  methods: {
    translate(string) {
      let str = string + '';
      let translations = ['intent', 'login', 'create', 'delete', 'update', 'start', 'finish', 'takeover'];
      translations.forEach(fragment => {
        str = str.replace(new RegExp(fragment,'gi'), this.lang.audit.events[fragment][this.languageSelected])
      });
      return str;
    },
    reloadReport() {
      this.key = new Date().getTime();
      this.loadReport(1);
    },
    handleChangePage(page) {
      this.loadReport(page);
    },
    setFilter(filter) {
      this.filter = filter;
      this.reloadReport();
    },
    loadReport(page) {
      this.$vs.loading()
      AuditService.getAudits(this.filter.from, this.filter.to, page - 1).then(res => {
        const report = res.data.rows;
        const reportTable = report.map(el=> {
          if(el.userEmail === 'null') {
            el.userEmail = ''; 
          }
          let model = '';
          if (el.idActivity) {
            model = `${el.idActivity} `;
          } else {
            model = 'create/update ';
          }
          if (el.channel) {
            model = model + el.channel.split(' ')[1].split('.')[0]; 
          }
          if (el.extendedData) {
            const data = JSON.parse(el.extendedData);
            if (data.objectId) {
              model += ` (${data.objectId})`
            } else if (data.fields && data.fields._id) {
              model += ` (${data.fields._id})`
            }
          }
          el.translated_event = this.translate(model)
          return el;
        })
        reportTable.sort(function(o1,o2){
          return moment(o1.dateTime).isBefore(moment(o2.dateTime)) ? 1 : -1;
        });
        this.reportTable = Array((page - 1) * 15).fill({ dateTime: null, userEmail: '', translated_event:'' }).concat(reportTable);
        this.totalItems = res.data.count;
      }).catch(e=> {
        this.$notify.error(
          this.lang.audit.error.title[
            this.languageSelected
          ],
          this.lang.audit.error.text[
            this.languageSelected
          ]
        )
        console.error(e);
      })
      .finally(() => this.$nextTick(()=> this.$vs.loading.close()))
    },
    downloadAll() {
      this.$emit("download", this.report);
    },
    stringify(val) {
      return JSON.stringify(val);
    },
    openDetails(index) {
      const jsonData = JSON.parse(this.reportTable[index].extendedData);
      this.popupTitle = this.reportTable[index].translated_event;
      this.auditDetail = JSON.stringify(jsonData.fields, null, 2);
      this.$refs.auditDetail.openPopup();
    }
  },
  watch: {
    languageSelected(newVal,oldVal) {
      if(oldVal && oldVal !== newVal) {
        this.reloadReport();
      }
    }
  }
}
</script>

<style>
.vs-table--tbody {
  z-index: 1 !important;
  min-width: 0% !important;
}

.vs-table--tbody-table {
  min-width: 0% !important;
}
</style>

<style scoped>
.center {
  margin: auto;
  width: 100%;
}
</style>

<style lang="scss">
.sessions-table {
  margin: 30px 0px;
  padding: 20px;
  background-color: #fafafa;
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.1);
  border-radius: 10px;

  .bot-item-btn {
    width: 33px !important;
    height: 33px !important;
    background: white !important;
    box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.08);
    border-radius: 2px;
    border: none;
    transition: all 0.2s ease-in-out;
    margin: 0px;
    &:not(:last-of-type) {
      margin-right: 5px;
    }

    &:hover {
      transform: scale(1.05);
    }

    .vs-icon {
      color: rgba(var(--vs-primary), 1) !important;
    }
  }


  .error-message {
    display: block;
    height: 19px;
    color: rgba(var(--vs-danger), 1);
    font-size: 0.65rem;
  }
  h2 {
    font-size: 18px;
    margin-bottom: 30px;
  }
  .btn-actions {
    display: flex;
    justify-content: flex-end;
  }

  .vs-pagination--buttons,
  .item-pagination {
    width: 30px;
    height: 30px;
  }
  .item-pagination {
    font-size: 0.8rem;
  }

  .con-vs-pagination {
    padding: 7px;
  }
}
.auditDetail{
  .vs-popup{
    width: 70%;
  }
}

</style>
